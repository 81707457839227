import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {ApiService} from "../services/api-service/api.service";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {


  constructor(private router: Router,
              private apiService: ApiService) {
  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot, routerSnapshot: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.apiService.auth.isValidSession()) {
      this.router.navigate(['/user/login']).catch();
      return false;
    } else {
      return true;
    }
  }

}
