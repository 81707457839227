import {Component, OnInit, ViewChild} from "@angular/core";
import {DownloadComponent} from "./download/download.component";
import {debounceTime} from "rxjs";
import {IoBridgeStatus} from "../../../../core/enums/ioBridgeStatus";
import {environment} from "../../../../../environments/environment";
import {CommissionManager} from "../../../../core/services/auth-service/support-services/CommissionManager";
import {ApiService} from "../../../../core/services/api-service/api.service";

@Component({
    selector: 'io-bridge-context-menu',
    templateUrl: './io-bridge.component.html',
    styleUrls: ['./io-bridge.component.scss'],
    standalone: false
})
export class IoBridgeComponent implements OnInit {

  @ViewChild(DownloadComponent) downloadComponent!: DownloadComponent;

  constructor(private apiService: ApiService) {
  }

  get iobStatusShort(): IoBridgeStatus {
    return CommissionManager.getInstance().bindingProcess.ioBridgeStatus;
  }

  get dtwStatusShort(): IoBridgeStatus {
    return CommissionManager.getInstance().bindingProcess.dtwStatus;
  }

  get isUsbAvailable(): boolean {
    return CommissionManager.getInstance().bindingProcess.dtwStatus == IoBridgeStatus.CONNECTED;
  }

  get isIoBridgeAvailable(): boolean {
    return this.iobStatusShort == IoBridgeStatus.CONNECTED || this.iobStatusShort == IoBridgeStatus.UPDATE_REQUIRED;
  }

  get ioBridgeNeedsUpdate(): boolean {
    return this.iobStatusShort == IoBridgeStatus.UPDATE_REQUIRED;
  }

  protected disableTestDTW: boolean = false;

  testDTW() {
    this.disableTestDTW = true
    this.apiService.iobService.fetchTestDTW().then(() => this.disableTestDTW = false)
  }

  protected disableDecryptDTW: boolean = false;

  decryptDTW() {
    this.disableDecryptDTW = true
    this.apiService.iobService.fetchDecryptDTW().then(() => this.disableDecryptDTW = false)
  }


  ngOnInit(): void {
    if (this.apiService.auth.isValidSession() && !this.apiService.iobService.isAdminOrHigher) {
      return;
    }

    this.apiService.iobService.iobConnectionChecker();

    this.apiService.auth.$loginOrLogout.pipe(debounceTime(50)).subscribe({
      next: value => {
        this.apiService.iobService.pause = value == 'logout' || !this.apiService.iobService.isAdminOrHigher || !this.apiService.auth.isValidSession();
      }
    });
  }

  protected readonly environment = environment;
}
