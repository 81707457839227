<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/ces/logo/dark.svg" alt="" height="32">
          </span>
          <span class="logo-lg">
            <img src="assets/ces/logo/dark.svg" alt="" height="32">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/ces/logo/dark.svg" alt="" height="32">
          </span>
          <span class="logo-lg">
            <img src="assets/ces/logo/dark.svg" alt="" height="32">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
        <i class="mdi mdi-menu"></i>
      </button>
    </div>

    <div class="d-flex">

      <!-- NOTIFICATION TODO add later
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          <i-feather class="icon-sm" name="bell"></i-feather>
          <span class="noti-dot bg-danger"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
             aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-15"> {{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h5>
              </div>
              <div class="col-auto">
                <a href="javascript:void(0);" class="small"> {{
                  'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="max-height: 250px;">
            <h6 class="dropdown-header bg-light">{{
              'HEADER.NOTIFICATIONS.NEW' | translate}}</h6>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <img src="assets/ces/user/user.svg" class="me-3 rounded-circle avatar-sm"
                       alt="user-pic">
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}
                    </p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold"><i
                      class="mdi mdi-clock-outline"></i> {{
                      'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <div class="avatar-sm me-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                        <i class="uil-shopping-basket"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold"><i
                      class="mdi mdi-clock-outline"></i> {{
                      'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <h6 class="dropdown-header bg-light">{{
              'HEADER.NOTIFICATIONS.EARLIER' | translate}}</h6>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <div class="avatar-sm me-3">
                      <span
                        class="avatar-title bg-soft-success text-success rounded-circle font-size-16">
                          <i class="uil-truck"></i>
                      </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}
                    </p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold"><i
                      class="mdi mdi-clock-outline"></i> {{
                      'HEADER.NOTIFICATIONS.THIRD.TIME' |
                        translate}}</p>
                  </div>
                </div>
              </div>
            </a>

            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <img src="assets/ces/user/user.svg" class="me-3 rounded-circle avatar-sm"
                       alt="user-pic">
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold"><i
                      class="mdi mdi-clock-outline"></i>{{
                      'HEADER.NOTIFICATIONS.FOUR.TIME' |
                        translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
              <i class="uil-arrow-circle-right me-1"></i> <span>{{
              'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>
            </a>
          </div>
        </div>
      </div>-->

      <!-- IO-Bridge Header Indicator -->
      @if (isIOBridgeEnabled) {
        <div [loadingCes]="isLoading" class="dropdown d-inline-block" ngbDropdown>

          <button type="button" class="btn header-item user text-start d-flex align-items-center"
                  id="page-header-dtw-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  ngbDropdownToggle>

            <div class="io-bridge">
            <span class="logo-lg">
              <img src="{{ 'image' | iobIndicator: {iob: iobStatus, dtw: dtwStatus} }}" alt="" height="36">
            </span>
              <div style="position: static !important;" id="io-bridge-in-progress"></div>
            </div>
          </button>

          <div class="dropdown-menu dropdown-menu-end pt-0" ngbDropdownMenu>
            <io-bridge-context-menu></io-bridge-context-menu>
          </div>
        </div>
      }

      <!-- Language -->
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item user text-start d-flex align-items-center"
                id="page-header-language-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                ngbDropdownToggle>
          <img class="rounded-circle border-2 border-primary header-profile-user"
               [src]="flagvalue | Trust:'resourceUrl'"
               alt="Header Avatar">
        </button>
        <div class="dropdown-menu dropdown-menu-end pt-0" style="min-width: unset;" ngbDropdownMenu>

          <!-- ACTIONS -->
          <a *ngFor="let entry of listLang" class="dropdown-item"
             (click)="setLanguage(entry.text, entry.lang, entry.flag)" role="button">
            <i class="text-muted font-size-16 align-middle me-1"><img [src]="entry.flag | Trust:'resourceUrl'"></i>
            <!--  <span class="align-middle">{{entry.text | translate}}</span> -->
          </a>
        </div>
      </div>

      <!-- USER -->
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item user text-start d-flex align-items-center"
                id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                ngbDropdownToggle>
          <img class="rounded-circle border-2 border-primary header-profile-user"
               [src]="currentUserImage | Trust:'resourceUrl'"
               alt="Header Avatar">
          <span class="ms-2 d-none d-sm-block user-item-desc">
              <span class="user-name">{{ currentUser }}</span>
              <span class="user-sub-title">{{ getCurrentUserRole() }}</span>
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-end pt-0" ngbDropdownMenu>
          <div class="p-3 bg-primary border-bottom">
            <h6 class="mb-0 text-white">{{ currentUser }}</h6>
            <p class="mb-0 font-size-11 text-white-50 fw-semibold">{{ getCurrentUserRole() }}</p>
          </div>

          <!-- ACTIONS -->
          @for(entry of modalContent; track $index){
            <a *ngIf="isLicenseBusiness || !entry.onlyBusiness" class="dropdown-item" (click)="openModal(entry)" role="button">
              <i class="text-muted font-size-16 align-middle me-1" [ngClass]="entry.icon"></i>
              <span class="align-middle">{{ entry.title | translate }}</span>
            </a>
          }
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()" role="button">
            <i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">{{ "USERMENU.LOGOUT" | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
