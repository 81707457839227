{
  "name": "@ces/cesentry-web-client",
  "version": "2.1.2",
  "browser": {
    "fs": false,
    "path": false,
    "os": false,
    "crypto": false
  },
  "scripts": {
    "ng": "ng",
    "start": "yarn run build-pro && ng serve",
    "start-dev": "yarn run build-dev && ng serve --configuration development",
    "start-swt": "yarn run build-swt && ng serve --configuration swt",
    "start-swt1": "yarn run build-swt1 && ng serve --configuration swt1",
    "start-local": "yarn run build-local && ng serve --configuration local",
    "start-stage": "yarn run build-local && ng serve --configuration stage",
    "start-local-proxy": "yarn run build-local && ng serve --configuration local --proxy-config src/proxy.conf.json",
    "build": "yarn install && yarn run buildversion && ng build",
    "build-local": "yarn install && yarn run buildversion && ng build --configuration local",
    "build-dev": "yarn install && yarn run buildversion && ng build --configuration development",
    "build-swt": "yarn install && yarn run buildversion && ng build --configuration swt",
    "build-swt1": "yarn install && yarn run buildversion && ng build --configuration swt1",
    "build-stage": "yarn install && yarn run buildversion && ng build --configuration stage",
    "build-pro": "yarn run build",
    "buildversion": "ts-node git.version.ts",
    "watch": "ng build --watch --configuration development",
    "test-unit": "ng test",
    "test-unit-ci": "ng test --browsers=ChromeHeadless --no-watch",
    "test-unit-coverage": "ng test --code-coverage",
    "analyse": "npx dpdm ./src/main.ts",
    "typedoc": "typedoc --mode modules -module commonjs --exclude **/*.spec.ts --out docs/typedoc src/app",
    "compodoc": "compodoc -p tsconfig.json",
    "generate-license-html": "license-report --output=html --config src/license-report-config.json --only=prod > src/assets/legal/open-source-licenses-generated.html "
  },
  "private": true,
  "dependencies": {
    "@angular-slider/ngx-slider": "^19.0.0",
    "@angular/animations": "19.1.6",
    "@angular/common": "19.1.6",
    "@angular/compiler": "19.1.6",
    "@angular/core": "19.1.6",
    "@angular/forms": "19.1.6",
    "@angular/localize": "^19.1.5",
    "@angular/platform-browser": "19.1.6",
    "@angular/platform-browser-dynamic": "19.1.6",
    "@angular/router": "19.1.6",
    "@compodoc/compodoc": "^1.1.26",
    "@ng-bootstrap/ng-bootstrap": "^18.0.0",
    "@ng-select/ng-select": "^14.2.2",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@types/jsdom": "^21.1.7",
    "angular-device-information": "^4.0.0",
    "bootstrap": "^5.3.3",
    "hammerjs": "^2.0.8",
    "html-to-pdfmake": "^2.5.13",
    "js-sha256": "^0.11.0",
    "jwt-decode": "^4.0.0",
    "lodash": "^4.17.21",
    "ngx-image-cropper": "^9.1.2",
    "ngx-lightbox": "^3.0.0",
    "pdfkit": "^0.16.0",
    "pdfmake": "^0.2.12",
    "rxjs": "^7.8.1",
    "typedoc": "^0.27.3",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.6",
    "@angular/cli": "19.1.7",
    "@angular/compiler-cli": "19.1.6",
    "@popperjs/core": "^2.11.8",
    "@types/file-saver": "^2.0.7",
    "@types/html-to-pdfmake": "^2.4.4",
    "@types/jasmine": "^5.1.4",
    "@types/lodash": "^4.17.7",
    "@types/node": "^22.5.4",
    "@types/node-fetch": "^2.6.11",
    "@types/pdfkit": "^0.13.4",
    "@types/pdfmake": "^0.2.9",
    "date-format-parse": "^0.2.7",
    "jasmine-core": "^5.3.0",
    "jest": "^29.7.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "ng-mocks": "^14.13.1",
    "ngentest": "^2.2.4",
    "ts-node": "^10.9.2",
    "typescript": "^5.7.3",
    "wait-port": "^1.0.4"
  }
}
