<div id="layout-wrapper">
    <!-- Top Bar -->
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()" (settingsButtonClicked)="onSettingsButtonClicked()">
    </app-topbar>
    <!-- Side Bar -->
    <app-sidebar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-sidebar>
    <div class="main-content">
        <div class="page-content">
              <!-- Main Content -->
              <router-outlet></router-outlet>
        </div>
        <!-- Footer -->
    </div>
</div>
