// this file is automatically generated by git.version.ts script
    export const versions = {
      beta: undefined,
      branch: 'feature/update',
      buildTime: '2025-02-14T15:46:34.724Z',
      package: '@ces/cesentry-web-client',
      revision: 'f9e4a4652',
      tag: 'v2.1.2',
      version: '2.1.2',
     };
